import { useQuery } from '@tanstack/react-query';
import { Card } from '@tremor/react';
import { useParams } from 'react-router-dom';
import { useGetUser } from '../../../../requests/api/back-office.requests';
import {
  CardTitle,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  LoadingPage,
  PageContainer,
} from '../../../../components';
import { UserList } from '../../common';
import { formatSubscriptionDate } from '../../../../utils';

export default function BackOfficeUserDetailsPage() {
  const { userId } = useParams();

  const getUser = useGetUser();

  const user = useQuery({
    queryKey: ['getBackOfficeUser'],
    queryFn: () => getUser(parseInt(userId!)),
  });

  if (!user.isSuccess) {
    return <LoadingPage hasError={user.isError} />;
  }

  const isTrial =
    user.data.user.status.subscription === 'trialing' ||
    user.data.user.status.subscription === 'trial ended';

  const trialEndDate = user.data.user.subscription.trialEndDate;
  const endDate = user.data.user.subscription.endDate;

  return (
    <PageContainer>
      <div className="grid gap-6">
        <Card>
          <CardTitle title="Overview" />
          <div className="mt-2">
            <UserList users={[user.data.user]} />
          </div>
        </Card>
        <Card>
          <CardTitle title="Subscription" />
          <div className="mt-4">
            <div className="flex space-x-4 text-tremor-default">
              {isTrial && (
                <div>
                  <span className="font-bold">Trial end date: </span>
                  {formatSubscriptionDate({ date: trialEndDate })}
                </div>
              )}
              {!isTrial && endDate && (
                <div>
                  <span className="font-bold">End date: </span>
                  {formatSubscriptionDate({ date: endDate })}
                </div>
              )}
            </div>
            <div className="mt-2">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell small>Plan</TableHeaderCell>
                    <TableHeaderCell small>Status</TableHeaderCell>
                    <TableHeaderCell small>Start date</TableHeaderCell>
                    <TableHeaderCell small>Trial end date</TableHeaderCell>
                    <TableHeaderCell small>End date</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.data.subscriptionHistory.map(subscription => (
                    <TableRow key={subscription.id}>
                      <TableCell size="sm">{subscription.plan.name}</TableCell>
                      <TableCell size="sm">{subscription.status}</TableCell>
                      <TableCell size="sm">
                        {formatSubscriptionDate({ date: subscription.startDate })}
                      </TableCell>
                      <TableCell size="sm">
                        {formatSubscriptionDate({
                          date: subscription.trialEndDate,
                          nullDate: '-',
                        })}
                      </TableCell>
                      <TableCell size="sm">
                        {formatSubscriptionDate({
                          date: subscription.endDate,
                          nullDate: '-',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </Card>
      </div>
    </PageContainer>
  );
}
