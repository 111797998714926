import { PropsWithChildren } from 'react';

export default function SecondaryButton({
  children,
  onClick,
  disabled,
  className,
}: PropsWithChildren & { onClick: () => void; disabled?: boolean; className?: string }) {
  return (
    <button
      className={`${className ?? ''} flex items-center justify-center rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300 disabled:hover:bg-inherit`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
