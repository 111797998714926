import { User } from '../../../../requests/api/back-office.requests';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from '../../../../components';
import { Link } from 'react-router-dom';
import AccountStatus from './AccountStatus';
import SubscriptionStatus from './SubscriptionStatus';

export default function UserList({ users }: { users: User[] }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell small first>
            User
          </TableHeaderCell>
          <TableHeaderCell small>Email</TableHeaderCell>
          <TableHeaderCell small>First name</TableHeaderCell>
          <TableHeaderCell small>Last name</TableHeaderCell>
          <TableHeaderCell small>Account status</TableHeaderCell>
          <TableHeaderCell small>Subscription status</TableHeaderCell>
          <TableHeaderCell small>Plan</TableHeaderCell>
          <TableHeaderCell small>User ID</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map(user => (
          <TableRow key={user.id}>
            <TableCell size="sm" textAlign="left" bold dark>
              <Link to={`/back-office/users/${user.id}`}>
                {user.firstName} {user.lastName}
              </Link>
            </TableCell>
            <TableCell size="sm">{user.email}</TableCell>
            <TableCell size="sm">{user.firstName}</TableCell>
            <TableCell size="sm">{user.lastName}</TableCell>
            <TableCell size="sm">
              <AccountStatus status={user.status.account} />
            </TableCell>
            <TableCell size="sm">
              <SubscriptionStatus status={user.status.subscription} />
            </TableCell>
            <TableCell size="sm">{user.subscription.plan.name}</TableCell>
            <TableCell size="sm">{user.id}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
