import { useMakeAuthenticatedGetRequest } from '../requests.hooks';
export type AggregationStep = 'hour' | 'day' | 'month';

export type BrowsersBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type CampaignsBlock = {
  items: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitDuration: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxVisitsPerItem: number | null;
};

export type CitiesBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type CountriesBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type DeviceCategoriesBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type DistributionBlock = {
  channelGroups: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
};

export type LandingPagesBlock = {
  items: {
    path: string;
    views: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxViewsPerItem: number | null;
};

export interface KeyMetricsBlock {
  totalVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalVisits: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalPageViews: {
    value: number | null;
    comparisonValue?: number | null;
  };
  averagePageViewsPerVisit: {
    value: number | null;
    comparisonValue?: number | null;
  };
  averageVisitDuration: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalNewVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
  totalOtherVisitors: {
    value: number | null;
    comparisonValue?: number | null;
  };
  engagementRate: {
    value: number | null;
    comparisonValue?: number | null;
  };
}

export type LanguagesBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type MediumsBlock = {
  items: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitDuration: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxVisitsPerItem: number | null;
};

export type OperatingSystemsBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type PagesBlock = {
  items: {
    path: string;
    views: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxViewsPerItem: number | null;
};

export type RegionsBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type ScreenResolutionsBlock = {
  items: {
    name: string;
    visits: { value: number };
  }[];
};

export type SourcesBlock = {
  items: {
    name: string;
    visits: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitDuration: {
      value: number | null;
      comparisonValue?: number | null;
    };
    visitors: {
      value: number | null;
      comparisonValue?: number | null;
    };
    engagementRate: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  maxVisitsPerItem: number | null;
};

interface TimeSeriesBlock {
  timeSeries: {
    dateTime: string;
    data: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  aggregationStep: AggregationStep;
}

interface VisitsBlock extends TimeSeriesBlock {}

interface PageViewsBlock extends TimeSeriesBlock {}

interface PageViewsPerVisitBlock extends TimeSeriesBlock {}

interface VisitDurationBlock extends TimeSeriesBlock {}

interface VisitorsBlock extends TimeSeriesBlock {}

interface EngagementRateBlock extends TimeSeriesBlock {}

export type Blocks = {
  browsers: BrowsersBlock;
  campaigns: CampaignsBlock;
  cities: CitiesBlock;
  countries: CountriesBlock;
  deviceCategories: DeviceCategoriesBlock;
  distribution: DistributionBlock;
  engagementRate: EngagementRateBlock;
  landingPages: LandingPagesBlock;
  keyMetrics: KeyMetricsBlock;
  languages: LanguagesBlock;
  mediums: MediumsBlock;
  operatingSystems: OperatingSystemsBlock;
  pages: PagesBlock;
  pageViews: PageViewsBlock;
  pageViewsPerVisit: PageViewsPerVisitBlock;
  regions: RegionsBlock;
  screenResolutions: ScreenResolutionsBlock;
  sources: SourcesBlock;
  visitDuration: VisitDurationBlock;
  visitors: VisitorsBlock;
  visits: VisitsBlock;
};

export type BlockName = keyof Blocks;

export type ShortcutDateRange = {
  type: 'shortcut';
  name: string;
};

export type CustomDateRange = {
  type: 'custom';
  startDate: string;
  endDate: string;
};

export type DateRange = ShortcutDateRange | CustomDateRange;

export interface Comparison {
  dateRange: DateRange;
  filters: Filter[];
}

export interface Filter {
  key: string;
  matchKey: string;
  value: string;
}

export interface FilterDefinition {
  key: string;
  matchTypes: {
    key: string;
  }[];
}

export function useGetTrafficBlock(): <T extends BlockName>({
  blockName,
  websiteId,
  dateRange,
  currentDate,
  filters,
  comparison,
}: {
  blockName: T;
  websiteId: string;
  dateRange: DateRange;
  currentDate: string;
  filters: Filter[];
  comparison?: {
    dateRange: DateRange;
    filters: Filter[];
  };
}) => Promise<Blocks[T]> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async ({
    blockName,
    websiteId,
    dateRange,
    currentDate,
    filters,
    comparison,
  }) => {
    const searchParams = new URLSearchParams();

    searchParams.append('websiteId', websiteId);
    searchParams.append('currentDate', currentDate);
    searchParams.append('dateRange', JSON.stringify(dateRange));
    searchParams.append('filters', JSON.stringify(filters));

    if (comparison) {
      searchParams.append('comparison', JSON.stringify(comparison));
    }

    const { data } = await makeAuthenticatedGetRequest(
      `/api/traffic/blocks/${blockName}?${searchParams.toString()}`,
    );
    return data;
  };
}

export function useGetFilterDefinitions(): () => Promise<FilterDefinition[]> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/traffic/filter-definitions');
    return data;
  };
}
