import { Menu } from '@headlessui/react';
import logo from './logo_with_title_298x96.png';
import { useSignOut } from '../../../requests/api/auth/sign-out';
import { getSupportEmailAddress, isDemoMode } from '../../../configuration';
import { RiUserLine } from '@remixicon/react';
import { Link } from 'react-router-dom';

export default function Header() {
  const signOut = useSignOut();

  return (
    <header className="flex items-center">
      <Link to="/">
        <img className="h-10" src={logo} alt="Linito" />
      </Link>
      <div className="grow"></div>
      <div className="mr-2 flex items-center">
        {isDemoMode() ? (
          <a
            href="https://app.linito.io/sign-up"
            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Sign up
          </a>
        ) : (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 ">
                <span className="sr-only">Open options</span>
                <RiUserLine aria-hidden="true" className="h-5 w-5" />
              </Menu.Button>
            </div>
            <Menu.Items
              // transition
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <Menu.Item>
                  <Link
                    to="/account-settings"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    Account settings
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <a
                    href={`mailto:${getSupportEmailAddress()}`}
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    Support
                  </a>
                </Menu.Item>
                <form action="#" method="POST">
                  <Menu.Item>
                    <button
                      onClick={signOut}
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Sign out
                    </button>
                  </Menu.Item>
                </form>
              </div>
            </Menu.Items>
          </Menu>
        )}
      </div>
    </header>
  );
}
