import { Button } from '@tremor/react';

export default function Tabs<T extends string>({
  tabs,
  selectedTab,
  onSelectedTabChange,
}: {
  tabs: Record<T, string>;
  selectedTab: T;
  onSelectedTabChange: (tab: T) => void;
}) {
  return (
    <div className="flex items-center space-x-3">
      {Object.keys(tabs).map(tab => (
        <Button
          key={tab}
          variant="light"
          disabled={tab === selectedTab}
          onClick={() => onSelectedTabChange(tab as T)}
        >
          {tabs[tab as T]}
        </Button>
      ))}
    </div>
  );
}
