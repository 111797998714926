import { createContext, useContext } from 'react';

export interface CurrentUser {
  id: number;
  email: string;
  status: {
    subscription:
      | 'trialing'
      | 'trial ended'
      | 'active'
      | 'pending cancellation'
      | 'cancelled';
    needsToSubscribe: boolean;
  };
  permissions: string[];
  subscription: {
    endDate: string | null;
    trialEndDate: string | null;
    plan: {
      name: string;
    };
  };
}

export const signInRoutePath = '/sign-in';
export const signUpRoutePath = '/sign-up';

export const CurrentUserContext = createContext<{
  currentUser: CurrentUser | null;
  refreshCurrentUser: () => Promise<void>;
}>({
  currentUser: null,
  refreshCurrentUser: async () => {},
});

export const useAuth = () => useContext(CurrentUserContext);
