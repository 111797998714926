import { Crisp } from 'crisp-sdk-web';
import { isDemoMode } from '../configuration';

const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;

const isCrispEnabled = CRISP_WEBSITE_ID !== undefined;

export function initLiveChatPlugin() {
  if (!isCrispEnabled) {
    console.log('Crisp is not enabled. Skipping initialization.');
    return;
  }

  try {
    Crisp.configure(CRISP_WEBSITE_ID);
  } catch (error) {
    console.error('Failed to initialize Crisp', error);
  }
}

export function setUserLiveChatData(user: { id: number; email: string }) {
  if (!isCrispEnabled) {
    console.log('Crisp is not enabled. Skipping setting user data.');
    return;
  }

  if (isDemoMode()) {
    console.log('Demo mode is enabled. Skipping setting user data.');
    return;
  }

  try {
    Crisp.user.setEmail(user.email);
    Crisp.session.setData({ userId: user.id });
  } catch (error) {
    console.error('Failed to set user data for Crisp', error);
  }
}
