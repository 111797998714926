import { Outlet } from 'react-router-dom';
import { PageContainer } from '../../components';
import { Navbar } from './navbar';

export default function MainPage() {
  return (
    <PageContainer>
      <Navbar />
      <Outlet />
    </PageContainer>
  );
}
